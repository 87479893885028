<template>
  <transition name="modal">
    <div class="modal-mask"  style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" style="max-height: 80vh; overflow: scroll;margin-top: 70px; background-color: transparent; padding: 0; font-family: 'sofia-pro';overflow-y: scroll;  " @click.stop>
        <div v-if="!request.pastOffer">
          <div style="display: flex; align-items: center; color: #2d3640; height: 60px; background-color: #2d3640; box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); border-radius: 8px 8px 0 0;">
            <div style="flex:1;text-align: left; color: white; margin-left: 12px">
              Teklif Gönder
            </div>
            <div style=" flex: 1; text-align: right; padding-right: 40px; ">
              <img @click="hide()" style=" opacity: 1; cursor:pointer;width: 16px; height: 16px;"  :src="$root.CDN_SITE + 'icon/ic_close_white.svg'">
            </div>
          </div>
          <div style=" padding: 20px 20px 30px; background-color: white; ">
            <div v-if="type === 'buyerRequest'" style="display: flex;">
              <div style="display: inline-block; margin-left: 30px;max-height: 170px; min-height: 100px; overflow-y: scroll; ">
                <label>{{ request.title }}</label>
                <span v-html="request.description" style="font-size: 13px; word-wrap: break-word"></span>
              </div>
            </div>
            <div v-if="type === 'buyerRequest'" class="hiddendiv"></div>
            <div v-if="type === 'buyerRequest'" class="line"></div>
            <div class="hiddendiv20"></div>
            <div v-if="gigs.length">
              <label>Lütfen bir İş ilanı seçin</label>
              <select v-model="selectedGig" class="super-drop" style="width: 100%; background-position-x: 97%;">
                <option :value="null">Seç...</option>
                <option v-for="gig in gigs" :value="gig.uuid">{{ gig.title }}</option>
              </select>
            </div>

            <div class="checkout-warning" v-if="!gigs.length">
              <img src="https://gcdn.bionluk.com/site/icon/icon_warning_red.svg">
              <p class="warning-header">Uyarı</p>
              <div class="separator"></div>
              <p class="warning-text">
                Aktif bir İş ilanın bulunmadığı için teklif veremezsin.
              </p>
              <router-link to="/panel/ilanlar">
                <button class="warning-action-button">
                  İş ilanlarım
                </button>
              </router-link>
            </div>

            <div v-if="type !== 'buyerRequest' || selectedGig" style="display: flex;flex-direction: column">
              <div class="hiddendiv20"></div>
              <label>Teklif Mesajı</label>
              <textarea v-model="description" style="width: auto; height: 120px;"></textarea>

              <div class="hiddendiv20"></div>

              <div class="">
                <div style="display: inline-block;margin-right: 20px;">
                  <label>İstediğin ücret</label>
                  <input v-model="price" placeholder="TL" type="number" style="width: 100px;"/>
                </div>
                <div style="display: inline-block;">
                  <label>Tahmini bitirme tarihi</label>
                  <input v-model="duration" type="number" placeholder="1 - 90 gün" style="width: 90px;"/>
                </div>
              </div>
            </div>

            <div v-if="gigs.length" style="text-align: right;margin: 10px 0">
              <button :disabled="disableToSend" @click="sendOffer()" class="super-button" >Gönder</button>
            </div>
          </div>
        </div>
        <div v-else>

          <div style=" display: flex; align-items: center; color: #2d3640; padding-left: 20px; height: 60px; border-top-left-radius: 8px; border-top-right-radius: 8px; background-color: #2d3640; box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1); border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
            <div style="flex:1;text-align: left">
              Teklif Detayı
            </div>
            <div style=" flex: 1; text-align: right; padding-right: 40px; ">
              <img @click="hide()" style=" opacity: 1; cursor:pointer;width: 16px; height: 16px;"  :src="$root.CDN_SITE + 'icon/ic_close_white.svg'">
            </div>
          </div>



          <div style=" padding: 20px 20px 30px; background-color: white; ">
            <div style="display: flex;">
              <div style="display: inline-block; height: 50px; max-width: 150px; text-align: center; ">
                <img :src="request.buyer.avatar_url" style="width: 45px;height: 45px;border: 3px solid #EAEAEA;border-radius: 30px!important; border-radius: 50%"/>
                <p style="font-size: 12px; color: #5e6b79;">{{ request.buyer.username }}</p>
              </div>
              <div style="display: inline-block; margin-left: 30px;max-height: 170px; overflow-y: scroll; ">
                <label>{{request.title}}</label>
                <span v-html="request.description" style="font-size: 13px;"></span>

              </div>
            </div>


            <div class="hiddendiv"></div>
            <div class="line"></div>
            <div class="hiddendiv10"></div>

            <div class="super-label green" style="max-width: 160px;">Gönderilen Teklif</div>
            <div class="hiddendiv10"></div>
            <label>Seçilen İş ilanı</label>
            <select :disabled="true"  v-model="selectedGig" class="super-drop" style="width: 100%; background-position-x: 97%;">
              <option v-for="gig in gigs" :value="gig.uuid">{{ gig.title }}</option>
            </select>
            <div class="hiddendiv20"></div>
            <label>Teklif Mesajı</label>
            <div style="max-height: 130px; min-height: 50px; overflow-y: scroll; font-size: 14px; ">
              <span v-html="pastOfferDetail.description"></span>
            </div>


            <div class="hiddendiv"></div>

            <div class="">
              <div style="display: inline-block; margin-right: 20px;">
                <label>İstediğin ücret</label>
                {{ pastOfferDetail.price }} TL
              </div>
              <div style="display: inline-block;">
                <label>Tahmini bitirme tarihi</label>
                {{ pastOfferDetail.duration }} gün
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </transition>


</template>

<script>

  export default {
    name: "src-pages-modals-offerForBuyerRequest-v1_mobile",
    data () {
      return {
        type: 'buyerRequest',
        request: {},
        receiver: null,
        gigs: [],
        selectedGig: null,
        description: null,
        price: null,
        duration: null,
        pastOfferDetail: {},
        disable:false
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      sendOffer() {
        this.disable = true;
        const type = this.type === 'buyerRequest' ? this.Constants.CUSTOM_OFFER_TYPES.REQUEST : this.Constants.CUSTOM_OFFER_TYPES.OFFER
        const uuid = this.type === 'buyerRequest' ? this.request.uuid : null
        const receiverUuid = this.type === 'buyerRequest' ? this.request.buyer.uuid : this.receiver.uuid
        this.api.seller.sendCustomOffer(receiverUuid, this.description, this.selectedGig, this.duration, this.price, type, uuid, this.$Progress)
          .then(({ data: result }) => {
            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              if (this.type === 'buyerRequest') {
                this.request.pastOffer = result.data.pastOffer
                this.request.pastOfferDetail = result.data.offerDetail
                this.$store.commit(this.types.DISABLE_MODAL)
              } else {
                return this.api.workstation.sendMessage(result.data.type, this.openedChannelID, null, result.data.external_id)
                  .then(({data}) => {
                    let result = data
                    if (result.success) {
                      this.$store.commit(this.types.DISABLE_MODAL);
                    } else {
                      this.$toasted.global.errorToast({description: result.message});
                    }
                  })
              }
            } else {
              this.$toasted.global.errorToast({description: result.message})
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
          .finally(() => {
            this.disable = false
          })
      }
    },

    computed: {
      getModalInfo() {
        if (!this.gigs.length) {
          let data = this.$store.state.activeModal.info.data;
          this.gigs = data.gigs;
          if (data.type !== 'buyerRequest') {
            this.type = data.type
            this.receiver = data.receiver;
            this.openedChannelID = data.openedChannelID;
          } else {
            this.request = data.request;
            if (this.request.pastOffer) {
              this.pastOfferDetail = this.request.pastOfferDetail;
              this.selectedGig = this.request.pastOfferDetail.gig_uuid;
            }
          }
        }
        return true;
      },

      disableToSend() {
        return !this.gigs.length || !(this.description && this.description.trim().length) || !this.selectedGig || !this.price || !this.duration || this.disable
      }
    },

  }

</script>

<style scoped>
  .checkout-warning {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    background-color: rgb(253, 241, 238);
    border: 1px solid rgb(232, 186, 186);
  }

  .warning-header {
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    color: rgb(242, 52, 52);
  }

  .warning-text {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: rgb(242, 52, 52);
  }

  .separator {
    height: 0;
    width: 100%;
    margin-top: 19px;
    border-bottom: solid 1px #e7b9b9;
  }

  .warning-action-button {
    width: 110px;
    height: 44px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c6c8ca;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    margin-top: 10px;
  }

  .warning-action-button:hover {
    background-color: #fbfbfb !important;
  }

</style>
